<template lang="pug">
.d-flex.justify-between.mt-16(
  v-if="!isCommittee"
)
  div.flex-grow-1
    v-text-field(
      class="w-full lg:w-1/2 xl:w-1/3 mb-4"
      v-model="search"
      @keyup="$emit('update', search)"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      outlined
      dense
      clearable
    )

  v-btn(
    :class="{ 'ring-1': showFilters }"
    depressed
    outlined
    color="primary"
    @click="$emit('toggle')"
  )
    v-icon( left ) mdi-filter
    span Filter
</template>

<script>
import { ref } from '@vue/composition-api'
 
export default {
  name: 'SearchAndFilter',

  setup(props, { root: { $store } }) {
    const { group } = $store.getters['auth/user']
    const isCommittee = group === 'committee'
    const showFilters = ref(props.showFilter);

    return {
      isCommittee,
      showFilters
    }
  }
}
</script>

<style>
</style>